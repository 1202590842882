export default {
    "files": {
        "files": "Файлдар",
        "info": "Ақпарат",
        "delete": "Жою",
        "pin": "Қосу",
        "open_in_new_tab": "Жаңа қойындыда ашу",
        "create_task": "Тапсырма жасау",
        "share": "Бөлісу",
        "edit": "Өзгерту",
        "archive_is_already_working": "Архив дайындалуда",
        "archive_is_working": "Архив дайындалуда",
        "error": "Қате",
        "download": "Жүктеу",
        "download_as_zip": "ZIP ретінде жүктеу",
        "name": "Аты",
        "description": "Сипаттама",
        "size": "Өлшем",
        "extension": "Кеңейту",
        "file_type": "Файл түрі",
        "cancel": "Бас тарту",
        "save": "Сақтау",
        "restore": "Қалпына келтіру",
        "detach": "Ажыратып алу"
    }
}